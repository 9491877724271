<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.shopify.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :openDefault="defaultStep === 1 || !isInstalled"
      :done="isInstalled || isConnected"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
    )
      template(#name) {{ $t('codeInsertV2.shopify.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.shopify.steps.0.texts.0')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/shopify/step_1_1.jpg')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.shopify.steps.0.texts.1') }}
        img.my-3(:src="require('@/assets/admin/img/insert-code/platforms/shopify/step_1_2.jpg')")
    om-accordion-item(
      :step="2"
      :openDefault="defaultStep === 2 || !isConnected"
      :done="isConnected"
      :trackPrefix="getSetupGuideTrackPrefix('step2')"
    )
      template(#name) {{ $t('codeInsertV2.shopify.steps.1.title') }}
      .code-insert-step-indent
        om-body-text(
          bt400md
          @click.native="openShopifyAppEmbed"
          v-html="$t('codeInsertV2.shopify.steps.1.texts.0', shopifyAppEmbedLink)"
        )
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/shopify/step_2_1.jpg')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.shopify.steps.1.texts.1') }}
          img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/shopify/step_2_2.jpg')")
      om-body-text.mt-5(bt400md v-html="$t('codeInsertV2.shopify.steps.1.texts.2')")
      om-toast.mt-5.mb-3(:closable="false" type="side")
        om-body-text(bt400md) {{ $t('codeInsertV2.shopify.steps.1.texts.3') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import shopifyAppExtensionMixin from '@/mixins/shopifyAppExtension';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Shopify',
    mixins: [childRouteMixin, shopifyAppExtensionMixin, domainStatusMixin, platformMixin],
    computed: {
      ...mapGetters(['isActiveShopifyDomain']),
      isInstalled() {
        return this.isActiveShopifyDomain(this.domain);
      },
      shopifyAppEmbedLink() {
        const { domain } = this.$route.query;
        if (!domain) {
          return;
        }
        // Generates a deep shopify link
        return { link: this.generateShopifyAppEmbedDeepLink(domain) };
      },
    },
    methods: {
      openShopifyAppEmbed(event) {
        if (event.target.tagName !== 'A') {
          return;
        }
        const { domain } = this.$route.query;
        if (!domain) {
          return;
        }
        // checks the inserted script, replaces v2 script with v3 if necessary, enables the toggle...)
        this.changeAppExtensionStatus(domain, true);
      },
    },
  };
</script>
